<template>
  <div class="">
    <MazSelect v-model="selectedItem"
               :placeholder="placeholder"
               :search-placeholder="searchPlaceholder"
               :loading="loading || cargando"
               :options="valores"
               :clearable="clearable" :search="search"
               :disabled="disabled" :required="required"  :error="error" :success="success"
               :position="position"
               :no-data="!valores && !valores.length"
               :config="Config"
               listWidth="100%"
               :inputValue="inputValue" :multiple="multiple"
               :itemHeight="70"
               :listHeight="350"
               :size="size"
               >
      <!--
          <MazSelect value="selectedItem"
             :placeholder="placeholder"
             :search-placeholder="searchPlaceholder"
             :loading="loading || cargando"
             :options="valores"
             clearable search
             :disabled="disabled" :required="required"  :error="error" :success="success"
             :position="position"
             :no-data="!valores && !valores.length"
             :config="Config"
             @request="searchResults">

    -->
      <template v-slot:icon-left>
        <i class="far fa-building"></i>
      </template>
      <template v-slot="{ option  }">

        <div v-if="option" class="option maz-text-color "
             :class="{
          'maz-text-white': option.isSelected
        }">
          <h4 class="mb-0">{{ option.etiqueta }}</h4>
          <span class="text-muted text-wrap">{{ option.descripcion }}</span>
        </div>

      </template>
      <template v-slot:no-results>
        <div class="text-center">
          <h3>No se encontro {{campo}}</h3>
          <hr class="my-2" />
          <h6 class="heading-small text-muted mb-2">Cuando busques y no se encuentre verás esto.</h6>
          <base-button @click="MostrarNuevo=true"><i class="fas fa-plus mr-2"></i>Agregar {{campo}}</base-button>
        </div>
      </template>
    </MazSelect>

    <NuevoModal :Mostrar.sync="MostrarNuevo"
                v-on:guardado="OnGuardado"></NuevoModal>

  </div>
</template>

<script>
  import { MazSelect } from 'maz-ui'
  import Servicio from '@/api-services/ubicaciones.service';
  import NuevoModal from './Nuevo.modal.vue';

  export default {
    name: 'Ubicaciones-Select',
    components: {
      MazSelect,
      NuevoModal
    },
    props: {
      loading: { type: Boolean, default: false },

      clearable: { type: Boolean, default: true },
      search: { type: Boolean, default: true },

      multiple: { type: Boolean, default: false },
      value: [String, Array],
      etiqueta: { type: String, default: null },

      id: { type: String, default: null },
      disabled: { type: Boolean, default: false }, required: { type: Boolean, default: false }, seleccionarUno: { type: Boolean, default: false },
 

      // Same as MazInput (options: `sm|md|lg`)
      size: { type: String, default: null },


      // Etiqueta a mostrar
      campo: { type: String, default: 'Ubicacion' },

      // Etiqueta a mostrar
      placeholder: { type: String, default: 'Ubicacion' },
      searchPlaceholder: { type: String, default: 'Buscar Ubicacion' },
      // Enable the dark mode
      dark: { type: Boolean, default: false }, success: { type: Boolean, default: false }, error: { type: Boolean, default: false },
      // Use color
      color: { type: String, default: 'primary' },

      campoEtiqueta: { type: String, default: 'etiqueta' },
      campoValor: { type: String, default: 'id' },

      // set the position of countries list (ex: `top`, `top right`, `bottom right`)
      position: { type: String, default: 'bottom' },

      //valores: { type: Array, default: [] },


    },
    data() {
      return {
        MostrarNuevo: false,
        selectedItem: null,
        cargando: false,

        inputValue: '',
        Erroneo: '',
        valores: [],
      }
    },
    computed: {

      Config() {
        {
          return {
            labelKey: this.campoEtiqueta,
            searchKey: this.campoEtiqueta,
            valueKey: this.campoValor
          };
        }
      },
    },

    watch: {
      value(valor) {

        this.selectedItem = valor;
      },
      inputValue(nValor) {
        console.debug(nValor);
      },
      selectedItem(valor) {
        if (this.valores != null) {
          const result = this.valores.filter(word => word[this.campoValor] == valor);
          if (result.length > 0) {
            var Objeto = result[0];
            this.$emit('update:etiqueta', Objeto[this.campoEtiqueta]); 

          }
        }

        this.$emit('input', valor);
        

      }
    },
    methods: {
      OnGuardado(Datos) {
        
        this.valores.push(Datos);
        this.selectedItem = Datos.id;
        /*
          //Actualiza todo el listado
        this.Actualiza(() => {
          this.selectedItem = Datos.id;
        });
        */
      },

      Actualiza(OnFinalizado) {
 
          Servicio.getsSelect(this, (response) => {
            this.valores = response.data.listado;
            this.selectedItem = (this.value == null && this.seleccionarUno) ? (this.valores.length == 1 ? this.valores[0][this.campoValor] : null) : this.value;
            if (OnFinalizado != null)
              OnFinalizado(response.data);
            this.$emit('finalizado', response.data);
          });
      }
    },

    mounted() {
      this.Actualiza();
    },
  }

</script>
<style src="maz-ui/lib/css/index.css"></style>
<style lang="scss" scoped>
  .option {
    margin-top: auto;
    margin-bottom: auto;
  }
</style>
