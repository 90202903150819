
import Base from '@/api-base/base'
const BaseURL = `Ubicaciones`;

export default {
  Mia: {},
  UbicacionesLocales: [],

  linea1(Ubicacion) {
    var N = "";
    if (Ubicacion === undefined || Ubicacion.dirCalle === undefined || Ubicacion.dirCalle === '' || Ubicacion.dirCalle === null)
      return "";
    N = Ubicacion.dirCalle;
    if (Ubicacion.dirCalleNo !== undefined && Ubicacion.dirCalleNo !== '' && Ubicacion.dirCalleNo !== null)
      N = `${N} ${Ubicacion.dirCalleNo}`;
    if (Ubicacion.dirinterior !== undefined && Ubicacion.dirinterior !== '' && Ubicacion.dirinterior !== null)
      N = `${N} ${Ubicacion.dirinterior}`;
    if (Ubicacion.colonia !== undefined && Ubicacion.colonia !== '' && Ubicacion.colonia !== null)
      N = `${N}, ${Ubicacion.colonia}`;
    if (Ubicacion.municipio !== undefined && Ubicacion.municipio !== '' && Ubicacion.municipio !== null)
      N = `${N}, ${Ubicacion.municipio}`;
    if (Ubicacion.ciudad !== undefined && Ubicacion.ciudad !== '' && Ubicacion.ciudad !== null)
      N = `${N}, ${Ubicacion.ciudad}`;
    if (Ubicacion.estado !== undefined && Ubicacion.estado !== '' && Ubicacion.estado !== null)
      N = `${N}, ${Ubicacion.estado}`;
    if (Ubicacion.pais !== undefined && Ubicacion.pais !== '' && Ubicacion.pais !== null)
      N = `${N}, ${Ubicacion.pais}`;
    if (Ubicacion.cp !== undefined && Ubicacion.cp !== '' && Ubicacion.cp !== null)
      N = `${N}, ${Ubicacion.cp}`;
    return N
  },
  TypeUbicacion(Ubicacion) {
    if (!Ubicacion.confirmada)
      return "default";
    return "info";
  },
  ActualizaLocales(Ubicaciones) {
    //  console.debug("ActualizaLocales(Ubicaciones)");
    //   console.debug(Ubicaciones);
    if (Ubicaciones == undefined || Ubicaciones == null)
      return false;
    for (var i = 0; i < Ubicaciones.length; i++) {
      this.ActualizaLocal(Ubicaciones[i]);
    }
    // console.debug(this.UbicacionesLocales);
    return true;
  },
  ActualizaLocal(Ubicacion) {

    for (var i = 0; i < this.UbicacionesLocales.length; i++) {
      if (this.UbicacionesLocales[i].ubicacionID == Ubicacion.ubicacionID) {
        //console.debug(this.UbicacionesLocales[i]);
        this.UbicacionesLocales[i] = Ubicacion;
        //console.debug(this.UbicacionesLocales[i]);
        return false;
      }

    }
    this.UbicacionesLocales.push(Ubicacion);
    return true;
  },

  GetsResponseSucursales: null,
  ListadoSucursales: [],
  GetsResponse: null,
  Listado: [],
  FotoURL(Registro) {
    return "/img/grupos.svg";
  },
  FiltrarGPS(Ubicaciones,lat, lng, acc, DistanciaMinima, RegistrosMaximos) {

    var UbicacionesEncontradas = [];

    if (DistanciaMinima == undefined)
      DistanciaMinima = 1;
    if (RegistrosMaximos == undefined)
      RegistrosMaximos = 1000;

    //console.log(`lat = ${lat}, lng = ${lng}, acc = ${acc}`);

    // console.log(SU.suscripcion.ubicaciones);
    for (var iSU = 0; iSU < Ubicaciones.length && UbicacionesEncontradas.length < RegistrosMaximos; iSU++) {
      var Ubicacion = Object.assign({}, Ubicaciones[iSU]);
      if (Ubicacion.borrado)
        continue;
     // console.log(Ubicacion);

      if (Ubicacion.gps != null && Ubicacion.gps.lat != null) {
        var D = this.distance(lat, lng, Ubicacion.gps.lat, Ubicacion.gps.lng, "m");

        //Por ahora solo se usará la precisión de la ubicacion
        //if (D < acc || D< Ubicacion.pGPS.Acc) {
        if (D < DistanciaMinima || D < Ubicacion.gps.acc) {
          Ubicacion.Distancia = D;
          Ubicacion.Requerido = Ubicacion.gps.acc;
          Ubicacion.Dentro = D < Ubicacion.gps.acc;
          UbicacionesEncontradas.push(Ubicacion);
          //   console.log(Ubicacion.pGPS);
//           console.log(`lat = ${lat}, lng = ${lng}, acc = ${acc}`);
        }
        // console.log();

        // console.log(`${Ubicacion.nombre} = ${D}, esta dentro = ${D < acc + Ubicacion.pGPS.Acc}, Ubicacion.pGPS.Acc = ${Ubicacion.pGPS.Acc}, Acc = ${acc}`);
      }

    }
    //console.log(SU.suscripcion.ubicaciones);

    //console.log(UbicacionesEncontradas);
    UbicacionesEncontradas.sort(function (a, b) {
      if (a.Distancia > b.Distancia) {
        return 1;
      }
      if (a.Distancia < b.Distancia) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return UbicacionesEncontradas;
  },
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  //:::                                                                         :::
  //:::  This routine calculates the distance between two points (given the     :::
  //:::  latitude/longitude of those points). It is being used to calculate     :::
  //:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
  //:::                                                                         :::
  //:::  Definitions:                                                           :::
  //:::    South latitudes are negative, east longitudes are positive           :::
  //:::                                                                         :::
  //:::  Passed to function:                                                    :::
  //:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
  //:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
  //:::    unit = the unit you desire for results                               :::
  //:::           where: 'M' is statute miles (default)                         :::
  //:::                  'K' is kilometers                                      :::
  //:::                  'N' is nautical miles                                  :::
  //:::                  'm' son metros por Moises Trejo                        :::
  //:::                                                                         :::
  //:::  Worldwide cities and other features databases with latitude longitude  :::
  //:::  are available at https://www.geodatasource.com                         :::
  //:::                                                                         :::
  //:::  For enquiries, please contact sales@geodatasource.com                  :::
  //:::                                                                         :::
  //:::  Official Web site: https://www.geodatasource.com                       :::
  //:::                                                                         :::
  //:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
  //:::                                                                         :::
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  distance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1 / 180;
      var radlat2 = Math.PI * lat2 / 180;
      var theta = lon1 - lon2;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") { dist = dist * 1.609344 }
      if (unit == "N") { dist = dist * 0.8684 }
      if (unit == "m") { dist = dist * 1609.344 }
      return dist;
    }
  },

  gets(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {

    return Base.getsBaseSuscripcion(Vista, BaseURL, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado);
  },
  getsGPS(Vista,  OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/GPS`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  getsSucursales(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/Sucursales`, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado);
  },
  getsSelectSucursales(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    if (this.GetsResponseSucursales != null)
      OnCorrecto(this.GetsResponseSucursales);

    return this.getsSucursales(Vista, { PaginaLen: 1000 }, (response) => {
      this.GetsResponseSucursales = response;
      this.ListadoSucursales = response.data.listado;
      if (OnCorrecto != undefined)
        OnCorrecto(response);

    }, OnErroneo, OnFinalizado);
  },
  getsSelect(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    if (this.GetsResponse != null)
      OnCorrecto(this.GetsResponse);
    return Base.getsBaseSuscripcion(Vista, BaseURL, { PaginaLen: 1000 }, (response) => {
      this.GetsResponse = response;
      this.Listado = response.data.listado;
      if (OnCorrecto != undefined)
        OnCorrecto(response);

    }, OnErroneo, OnFinalizado);
  },
  put(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.put(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  post(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.postBaseSuscripcion(Vista, BaseURL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  get(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/${id}`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  delete(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.deleteBaseSuscripcion(Vista, `${BaseURL}/${id}`, OnCorrecto, OnErroneo, OnFinalizado);
  },
}


